import { render, staticRenderFns } from "./ka-user-profile-tab-notification-settings.vue?vue&type=template&id=4d4a495c&scoped=true&"
import script from "./ka-user-profile-tab-notification-settings.vue?vue&type=script&lang=js&"
export * from "./ka-user-profile-tab-notification-settings.vue?vue&type=script&lang=js&"
import style0 from "./ka-user-profile-tab-notification-settings.vue?vue&type=style&index=0&id=4d4a495c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d4a495c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KCheckbox from '@web/components/core/k-checkbox'
import KInput from '@web/components/core/k-input'
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {KButton,KCheckbox,KInput,VCol,VContainer,VExpandTransition,VRow})
