<template>
    <k-layout>
        <header class="tw-bg-white tw-pt-12 tw-px-4">
            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-md">
                <div class="tw-flex tw-w-full xl:tw-space-x-8">
                    <div class="tw-w-full">
                        <h1 class="tw-heading-1">{{ $t('profile.title') }}</h1>
                    </div>
                </div>
            </div>

            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-md tw-mt-8">
                <div class="tw-flex tw-w-full xl:tw-space-x-8">
                    <div class="tw-w-full">
                        <v-tabs show-arrows icons-and-text v-model="tab" background-color="transparent">
                            <v-tab
                                :ripple="false"
                                class="tw-font-bold tw-tracking-normal tw-normal-case tw-text-gray-500 hover:tw-text-gray-800 tw-transition-colors tw-duration-200 tw-min-w-0 tw-mr-4"
                                active-class="tab--active"
                            >
                                {{ $t('profile.subtitles.account_settings') }}
                            </v-tab>
                            <v-tab
                                class="tw-font-bold tw-tracking-normal tw-normal-case tw-text-gray-500 hover:tw-text-gray-800 tw-transition-colors tw-duration-200 tw-min-w-0 tw-mr-4"
                                active-class="tab--active"
                            >
                                {{ $t('profile.subtitles.email_settings') }}
                            </v-tab>
                            <v-tabs-slider class="tabs-slider"></v-tabs-slider>
                        </v-tabs>
                    </div>
                </div>
            </div>
        </header>

        <main v-if="isUserSignedIn" class="tw-mt-8 tw-px-4 tw-mb-24">
            <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-md">
                <v-tabs-items v-model="tab" class="page-tab-items-container tw-rounded-2xl">
                    <v-tab-item class="page-tab-item-container">
                        <ka-user-profile-tab-profile-settings />
                    </v-tab-item>

                    <v-tab-item class="page-tab-item-container">
                        <ka-user-profile-tab-notification-settings />
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </main>
    </k-layout>
</template>

<script>
// import KaUserVerificationTab from "./ka-user-verification-tab";
import KaUserProfileTabProfileSettings from "./ka-user-profile-tab-profile-settings";
import KaUserProfileTabNotificationSettings from "./ka-user-profile-tab-notification-settings";
import {mapGetters} from 'vuex'

export default {
    components: {
        // KaUserVerificationTab,
        KaUserProfileTabProfileSettings,
        KaUserProfileTabNotificationSettings
    },
    created() {
        this.$store.dispatch("APPBAR_NON_TRANSPARENTABLE");

        const { tab } = this.$route.query || {};
        if (tab === "emailove-nastavenia") {
            this.tab = 1;
        }
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        ...mapGetters({
            isUserSignedIn: 'AUTH/getIsUserSignedIn'
        })
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.tab {
    font-family: "soleil", sans-serif !important;
    text-transform: none;
    font-size: map-get(map-get($text, title), size);
    letter-spacing: 0em;
    font-weight: map-get(map-get($text, title), font-weight);
}

.tab--active {
    color: $koderia-black !important;
}

.tabs-slider {
    color: $koderia-black !important;
}

.page-tab-items-container {
    background-color: white;
    box-shadow: 0 0.063rem 0.25rem $koderia-shadow;
}

.page-tab-item-container {
    padding: 3.5rem 4rem 4rem 4rem;

    @media (max-width: 63.938rem) {
        padding: 1.5rem 1rem;
    }
}
</style>
